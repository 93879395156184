// src/App.js
import React from 'react';
import HeroSection from './components/HeroSection';
import OpeningHours from './components/OpeningHours';
import PraxisInfo from './components/PraxisInfo';
import UrlaubsFormular from './components/UrlaubsFormular';
import Labors from './components/Labors';
import KontaktTermine from './components/KontaktTermine';
import Teamsection from './components/Teamsection';
import Anfahrt from './components/Anfahrt';
import InfoAnfang from './components/InfoAnfang';
import Header from './components/Header';
// index.js or App.js



function App() {
  return (
    <>
      <Header />
      <HeroSection />
      <div className="container my-5">
        <OpeningHours id="openingHours"/>
        <InfoAnfang />
        <UrlaubsFormular id="urlaubsFormular"/>
        <KontaktTermine id="kontakt"/>
        <Teamsection id="team"/>
        <Anfahrt id="anfahrt"/>
        <PraxisInfo id="praxisInfo"/>
        <Labors id="labors"/>
      
      </div>
    </>
  );
}

export default App;
