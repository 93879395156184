// src/components/PraxisInfo.js
import React from 'react';
import './InfoAnfang.css';

const InfoAnfang = () => {
  return (
    <section className="praxis-details py-5">
      <div className="container">
        {/* SO IST ES BEI UNS */}
        <h2 className="text-uppercase mb-4">„Ich behandle Sie als Frau und als Patientin“</h2>
        <p>
         Ich begleite Sie bei allen frauenspezifischen Themen im Weg des Lebens wie 
         Sexualität, Verhütung, Kinderwunsch, Schwangerschaft, Geburt, Wechseljahre und Hormonhaushalt .
          Ich biete Betreuung in der Vorsorge sowie bei gynäkologischen und geburtshilflichen Erkrankungen.
        </p>


      </div>
    </section>
  );
};


export default InfoAnfang;
