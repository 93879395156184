// src/components/HeroSection.js
import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <section className="hero-section d-flex align-items-center justify-content-center">
      <div className="top-right-info">
        <p>Weidlingerstrasse 4/4</p>
        <p>3400 Klosterneuburg</p>
        <p>Tel: 02243/22380</p>
        <p>
          <a href="mailto:office@gadner.com">office@gadner.com</a>
        </p>
      </div>
      <div className="hero-overlay"></div>
      <div className="container position-relative text-center text-white">
        <h1 className="display-4 fw-bold">Dr. Monika Gadner</h1>
        <p className="lead">Frauenärztin | Alle Kassen &amp; Privat</p>
        <a href="#kontakt" className="btn btn-light btn-lg mt-3">Termin vereinbaren</a>
      </div>
    </section>
  );
};

export default HeroSection;
