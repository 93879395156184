// src/components/AssoziierteLabors.js
import React from 'react';
import './Labors.css';
import logo from '../images/logo.png'; // Adjust to your actual file path

const Labors = () => {
  return (
    <footer className="labors-footer">
      <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
        
        {/* LEFT SIDE: Logo + Title */}
        <div className="d-flex align-items-center mb-3 mb-md-0">
          <img
            src={logo}
            alt="Dr. Monika Gadner Logo"
            className="footer-logo me-2"
          />
          <h2 className="footer-title m-0">Meine assoziierten Labors</h2>
        </div>
        
        {/* RIGHT SIDE: Lab Links */}
        <ul className="list-inline mb-0">
          <li className="list-inline-item me-3">
            <a
              href="https://www.labors.at/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              Labors.at
            </a>
          </li>
          <li className="list-inline-item me-3">
            <a
              href="https://dr-kosak.at/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              Laboratorium Dr. Kosak
            </a>
          </li>
          <li className="list-inline-item me-3">
            <a
              href="https://www.labors-patho.at/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              Labor für Zytologie und Histologie Dr. Ulm
            </a>
          </li>
          <li className="list-inline-item">
            <a
              href="https://www.labor-mustafa.at/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              Labor Mustafa
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Labors;
