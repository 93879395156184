// src/components/TeamSection.js
import React from 'react';
import './Teamsection.css';
import teamImage from '../images/team-picture.jpeg'; // Replace with your team picture file

const Teamsection = ({ id }) => {
    return (
      <section id={id} className="team-section py-5">
        <div className="container">
          <h2 className="text-uppercase mb-4 text-center">Unser Team</h2>
          
          <div className="team-image-container">
            <img
              src={teamImage}
              alt="Unser Team"
              className="img-fluid rounded shadow"
            />
            {/* Overlay with names at the bottom */}
            <div className="team-overlay">
              <div className="person-info">
                <div className="person-name">Doris Gitschtaler</div>
                <div className="person-role">Ordinationsassistentin</div>
              </div>
              <div className="person-info">
                <div className="person-name">Monika Gadner</div>
                <div className="person-role">FRAUENÄRZTIN</div>
              </div>
              <div className="person-info">
                <div className="person-name">Claudia Todt</div>
                <div className="person-role">Ordinationsassistentin</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

export default Teamsection;
