import React, { useState } from 'react';
import './Header.css';
import logo from '../images/logo.png'; // Replace with your actual logo file path

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => setExpanded(!expanded);

  return (
    <header className="main-header">
      <nav className="navbar navbar-expand-md">
        <div className="container">
          {/* LOGO */}
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img src={logo} alt="Dr. Monika Gadner Logo" className="logo-img me-2" />
            <span className="logo-text">Dr. Monika Gadner</span>
          </a>
          {/* Hamburger Toggler for Mobile */}
          <button 
            className="navbar-toggler" 
            type="button" 
            onClick={toggleNavbar}
            aria-controls="navbarNav"
            aria-expanded={expanded}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* Collapsible Nav Links */}
          <div className={`collapse navbar-collapse ${expanded ? "show" : ""}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="#openingHours" onClick={() => setExpanded(false)}>Öffnungszeiten</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#urlaubsFormular" onClick={() => setExpanded(false)}>Urlaubsplan</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#kontakt" onClick={() => setExpanded(false)}>Kontakt</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#team" onClick={() => setExpanded(false)}>Team</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#anfahrt" onClick={() => setExpanded(false)}>Anfahrt</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#praxisInfo" onClick={() => setExpanded(false)}>Infos</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
