// src/components/PraxisInfo.js
import React from 'react';
import './PraxisInfo.css';
import labImage from '../images/moni-unscharf.jpeg'; // Update with your actual image file

const PraxisInfo = ({ id }) => {
  return (
    <section id={id} className="praxis-details py-5">
      <div className="container">
        {/* SO IST ES BEI UNS */}
        <h2 className="text-uppercase mb-4">So ist es bei uns</h2>
        <p>Alle Kassenleistungen werden bei mir über die e card abgewickelt.</p>

<p>Über allfällige Privatleistungen, die die Krankenkasse nicht übernimmt, werden Sie immer vorab informiert. 
Zahlungsmöglichkeiten sind bar (alle Rechnungen bis 30 Euro) oder über online banking möglich.</p>

<p>Wir haben für unsere Patientinnen ein Kontingent an Akut- und Einschubterminen täglich.</p>

<p>Es kann trotz Terminvereinbarungen zu Wartezeiten kommen. Wir bitten um Verständnis. 
Sie werden bei der Anmeldung über Wartezeiten informiert. </p>

<p>Kommen Sie bitte alleine zu Ihrem Termin, Begleitpersonen sind nach Rücksprache willkommen.</p>

<p>Bei Symptomen von Grippe, Covid,… tragen Sie bitte einen Mund Nasen Schutz. </p>

<p>Falls Sie ein Thema haben, das besonders viel Zeit beansprucht bitte ich Sie einen Privattermin zu buchen.</p>

<p>Privattermine sind jederzeit außerhalb der Kassenöffnungszeiten buchbar. 
Die Rechnung kann nicht bei der gesetzlichen Versicherung eingereicht werden, da ich keine Wahlärztin bin. 
Geld zurück gibt es lediglich bei privaten ambulanten Zusatzversicherungen. </p>


<p>Geburtsbegleitung und private Operationen biete ich in der Privatklinik Döbling an. {' '}
          <a
            href="https://www.privatklinik-doebling.at/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-sm custom-btn ms-2"
          >
            Link Privatklinik Döbling
          </a></p>
      

        {/* UNSER SERVICE TELEMEDIZIN */}
        <h2 className="text-uppercase mt-5 mb-4">Unser Service Telemedizin</h2>
        <div className="row align-items-center">
          <div className="col-md-6">
            <p>
            Wir brauchen wieder ausnahmslos für jede telemedizinische Leistung  einmal im Quartal bei ÖGK oder alle zwei Monate 
            bei kleinen Kassen Ihre e card. Nur so können wir Dauerrezepte ausstellen. Befundbesprechungen sind auf Grund der 
            wieder verschärften Datenschutzbestimmungen nur persönlich oder in von mir ausgewählten Fällen telefonisch möglich.
            </p>
            <p>
            Gesundheitliche Daten oder Befunde, die Sie per mail schicken sind nicht verschlüsselt und damit nicht datengeschützt. 
            Ebenso besteht keine Leseverpflichtung meinerseits. Von mir erhobene Befunde können Sie sich persönlich zu den Öffnungszeiten 
            abholen. Eine Versendung per Post ist eine Privatleistung. Laborbefunde können Sie online über meine assoziierten Labors 
            datengeschützt anfordern.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <img
              src={labImage}
              alt="Foto Moni unscharf mit Schallkopf"
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
   
      </div>
    </section>
  );
};


export default PraxisInfo;
