// src/components/UrlaubsFormular.js
import React, { useEffect, useState } from 'react';
import './UrlaubsFormular.css';

const UrlaubsFormular = ({ id }) => {
  const [vacations, setVacations] = useState([]);
  // Your published CSV link from Google Sheets
  const SHEET_URL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTy7zDJztsI31RVNpz_JUhs2akbGqY5HDKeMjeULZ8Bxw4NSqK-4_253Nmbj1zM7bS8jwyCwtQu1SzC/pub?output=csv';

  useEffect(() => {
    fetch(SHEET_URL)
      .then((res) => res.text())
      .then((csvData) => {
        // Split CSV data by newline and remove header row
        const lines = csvData.split('\n').map((line) => line.trim());
        lines.shift();
        setVacations(lines);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <section id={id} className="vacation-section py-5">
      <div className="container">
        <div className="text-center">
          <h2 className="text-uppercase mb-3">Unsere Urlaube / Fortbildungen</h2>
          <p className="text-muted lead">
            Während dieser Zeit ist unsere Ordination geschlossen. 
            Bitte vereinbaren Sie Termine frühzeitig.
          </p>
          <ul className="list-group mt-4">
            {vacations.map((date, index) => (
              <li key={index} className="list-group-item">
                {date}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default UrlaubsFormular;
