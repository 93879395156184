// src/components/KontaktTermine.js
import React from 'react';
import './KontaktTermine.css';

const KontaktTermine = ({ id }) => {
  return (
    <section id={id} className="kontakt-termine-section py-5">
      <div className="container">
        <h2 className="text-uppercase mb-4 text-center">So melden Sie sich an</h2>
        <div className="text-center">
          <p className="mb-1">
            <strong>Tel:</strong> 02243/ 22380
          </p>
          <p className="mb-4">
            <strong>E-Mail:</strong>{' '}
            <a href="mailto:office@gadner.com" className="text-decoration-none link-dark">
              office@gadner.com
            </a>
          </p>
          <button
            className="btn custom-btn mb-4"
            onClick={() => window.open('https://www.doctena.at/de/fachgebiet/gynaekologie-frauenheilkunde-geburtshilfe/dr-monika-gadner-310722', '_blank')}
          >
            Online Termine
          </button>
        </div>
        <div className="mt-4">
          <p className="text-muted lead text-center">
            Wir sind eine Terminordination. Terminvereinbarungen sind telefonisch zu den
            Öffnungszeiten oder per E-Mail möglich. Als Service für unsere bestehenden Patientinnen
            steht das Online-Buchungsservice rund um die Uhr auf der Website zur Verfügung.
          </p>
          <p className="text-muted lead text-center">
          Bitte sagen Sie bereits vereinbarte Termine 24 Stunden vorher telefonisch oder per e mail ab, 
          da wir sie nur so nochmals vergeben können und wir so auch Ihre Wartezeit auf einen neuerlichen Termin verringern können.
          </p>
        </div>
      </div>
    </section>
  );
};

export default KontaktTermine;
