// src/components/AnfahrtSection.js
import React from 'react';
import './Anfahrt.css';
import ordinationImage from '../images/ordi.jpeg'; // Replace with your actual image

const Anfahrt = ({ id }) => {
  return (
    <section id={id} className="anfahrt-section py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* LEFT COLUMN: TEXT + BUTTONS */}
          <div className="col-md-6">
            <h2 className="text-uppercase mb-4">So kommen Sie zu uns</h2>
            <p className="mb-3">
              Unsere Ordination ist barrierefrei erreichbar und ausgestattet.
              Die Ordination liegt nur wenige Schritte vom Weidlinger Bahnhof in Klosterneuburg entfernt.
              Sie ist gut mit der Schnellbahn oder dem Bus von Wien bzw. von Tulln erreichbar.
              Beachten Sie die Kurzparkzone in der Umgebung, falls Sie mit dem Auto kommen.
            </p>
            <div className="mb-4">
            <button
              className="btn custom-btn mb-4"
              onClick={() =>
                window.open(
                  'https://www.google.com/maps/search/?api=1&query=Weidlingerstrasse+4%2F4%2C+3400+Klosterneuburg',
                  '_blank'
                )
              }
            >
              Google Maps
            </button>

            </div>
          </div>

          {/* RIGHT COLUMN: IMAGE */}
          <div className="col-md-6">
            <img
              src={ordinationImage}
              alt="Unsere Ordination"
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Anfahrt;
