// src/components/OpeningHours.js
import React from 'react';
import './OpeningHours.css';

const OpeningHours = ({ id }) => {
  return (
    <section id={id} className="opening-hours-section mb-5">
      <h2 className="mb-3">Öffnungszeiten</h2>
      <ul className="list-group">
        <li className="list-group-item">Montag: 13-18 Uhr</li>
        <li className="list-group-item">Dienstag: 8-13 Uhr</li>
        <li className="list-group-item">Mittwoch: 13-18 Uhr</li>
        <li className="list-group-item">Donnerstag: 8-13 Uhr</li>
        <li className="list-group-item">Privattermine nach Vereinbarung auch außerhalb der angegebenen Zeiten</li>
      </ul>
    </section>
  );
};

export default OpeningHours;
